// TO DO: Fix types for Drip Campaigns and improve the ICampaignGql interface

import { ApolloClient } from '@apollo/client'
import type { ApolloContext } from '~/services/types/Gql'
import getOutOfOfficeSettings from '@/graphql/out-of-office/getOutOfOfficeSettings.gql'
import outOfOfficeCreate from '@/graphql/out-of-office/outOfOfficeCreate.gql'
import outOfOfficeUpdate from '@/graphql/out-of-office/outOfOfficeUpdate.gql'
import outOfOfficeDelete from '@/graphql/out-of-office/outOfOfficeDelete.gql'

type RouteIds = {
    campaignId: string,
    phoneNumberId: string,
    tenantId: string
}
type PageParams = {
    page: number,
    itemsPerPage: number
}
type OutOfOfficeCreateParams = {
    message: string,
    startDate: string,
    endDate: string,
    respondNewContact: Boolean
}
interface IOutOfOfficeGql {
  $apollo: ApolloClient<any>,
  getSettings(routeIds: RouteIds, pageParams: PageParams) : Promise<any>,
  outOfOfficeCreate(routeIds: RouteIds, outOfOfficeCreateParams: OutOfOfficeCreateParams) : Promise<any>
}
export default class OutOfOfficeGql implements IOutOfOfficeGql {
  $apollo: ApolloClient<any>

  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  outOfOfficeCreate ({ phoneNumberId, tenantId }: RouteIds, { message, startDate, endDate, respondNewContact }: any): Promise<any> {
    return this.$apollo.mutate({
      mutation: outOfOfficeCreate,
      variables: {
        message,
        phoneNumberId,
        startDate,
        tenantId,
        respondNewContact,
        endDate
      }
    }).then((result: any) => result?.data?.outOfOfficeCreate)
  }

  outOfOfficeDelete ({ phoneNumberId, tenantId }: RouteIds, { id }: any): Promise<any> {
    return this.$apollo.mutate({
      mutation: outOfOfficeDelete,
      variables: {
        outOfOfficeId: id,
        phoneNumberId,
        tenantId
      }
    }).then((result: any) => result?.data?.outOfOfficeDelete)
  }

  outOfOfficeUpdate ({ phoneNumberId, tenantId }: RouteIds, { outOfOfficeId, startDate, endDate, message, respondNewContact }: any): Promise<any> {
    return this.$apollo.mutate({
      mutation: outOfOfficeUpdate,
      variables: {
        outOfOfficeId,
        tenantId,
        startDate,
        message,
        endDate,
        respondNewContact,
        phoneNumberId
      }
    }).then((result: any) => result?.data?.outOfOfficeUpdate)
  }

  getSettings ({ tenantId, phoneNumberId } : RouteIds, { page, itemsPerPage }: PageParams) : Promise<any> {
    return this.$apollo.query({
      query: getOutOfOfficeSettings,
      fetchPolicy: 'no-cache',
      variables: {
        phoneNumberId,
        tenantId,
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage
      }
    }).then((result: any) => result?.data.outOfOfficeList)
  }
}
